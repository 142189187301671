import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Input,
  Row,
  Col,
  Radio,
  Button,
  Divider,
  Select,
  Checkbox,
  Switch,
  Popover,
} from 'antd';
import Editor from 'react-simple-code-editor';
import { highlight, languages, } from 'prismjs/components/prism-core';
import ReactJson from 'react-json-view';
import { get, find } from 'lodash';
import {
  fetchAnalyticsReport,
  setReportData,
  testReportQuery,
  submitReportChanges,
  createNewAnalyticReport,
  fetchAllHubList,
  fetchAllClusterList,
} from './actions';
import ListSelector from './_components/ListSelector';
import EditableColumns from './_components/EditableColumns';
import FullPageLoading from '../../component/FullPageLoading';
import AddActions from './_components/AddActions';
// import s from './Analytics.module.scss';
import s from './AnalyticsReport.module.scss';
import { getTimezoneList } from '../../utils/helpers';
import AddUpdateLog from './_components/AddUpdateLog';

const { Option } = Select;
// The userids contain ids from dev and prod region both for developers.
const allowedDefaultAccessAdmins = [
  2602626, 2602795, 73206, 2602630, 2329836, 4618607, 5078146, 3848318,
];

const placeholder = `[ WITH [ RECURSIVE ] with_query [, ...] ]
  SELECT [ ALL | DISTINCT [ ON ( expression [, ...] ) ] ]
  [ FROM from_item [, ...] ]
  [ WHERE condition ]
  [ GROUP BY grouping_element [, ...] ]
  [ HAVING condition [, ...] ]
`;

const DATABASE_OPTIONS = [
  { value: 'primary', label: 'Primary' },
  { value: 'analytics', label: 'Analytics' },
];

const QUERY_LEVEL_OPTIONS = [
  { value: 'hub', label: 'Hub' },
  { value: 'cluster', label: 'Cluster' },
];

class AnalyticsReportList extends React.Component {
  componentDidMount() {
    const {
      fetchAnalyticsReport,
      match,
      createNewAnalyticReport,
      fetchAllHubList,
      fetchAllClusterList,
    } = this.props;
    fetchAllHubList();
    fetchAllClusterList();
    const id = get(match, 'params.id');
    if (id) {
      fetchAnalyticsReport(id);
    } else {
      createNewAnalyticReport();
    }
  }

  getAllHeaderOptions = () => {
    const { report = {} } = this.props;
    const { reportData = {} } = report;
    const { allHeaders } = reportData;
    if (Array.isArray(allHeaders)) {
      return allHeaders.map(ah => <Option value={ah}>{ah}</Option>);
    }
    return [];
  };

  getAllSortColsOptions = () => {
    const { report = {} } = this.props;
    const { reportData = {} } = report;
    const { allSortColumns } = reportData;
    if (Array.isArray(allSortColumns)) {
      return allSortColumns.map(ah => <Option value={ah}>{ah}</Option>);
    }
    return [];
  };

  getAllFilterOptions = () => {
    const { report = {} } = this.props;
    const { reportData = {} } = report;
    const { allFilters } = reportData;
    if (Array.isArray(allFilters)) {
      return allFilters.map(ah => <Option value={ah}>{ah}</Option>);
    }
    return [];
  };

  setReportState = key => e => {
    const value = get(e, 'target') ? get(e, 'target.value') : e;
    const { setReportData } = this.props;
    setReportData({ [key]: value });
  };

  setAllHubs = e => {
    const value = get(e, 'target.checked');
    this.setReportState('all_hubs')(value);
  };

  setMultipleOption = i => e => {
    const value = get(e, 'target.checked');
    this.updateFilter(i, 'multiple')(value);
  };

  setModuleMode = e => {
    const value = e ? 'OR' : 'AND';
    this.setReportState('module_mode')(value);
  };

  addHeader = () => {
    const { report = {} } = this.props;
    const { reportData = {} } = report;
    let { headers = [] } = reportData;
    headers.push({ column: '', title: '' });
    headers = [...headers];
    this.setReportState('headers')(headers);
  };

  removeHeader = index => () => {
    const { report = {} } = this.props;
    const { reportData = {} } = report;
    let { headers = [] } = reportData;
    if (headers && headers[index]) {
      headers.splice(index, 1);
    }
    headers = [...headers];
    this.setReportState('headers')(headers);
  };

  updateHeader = (index, key) => e => {
    const value = get(e, 'target') ? get(e, 'target.value') : e;
    const { report = {} } = this.props;
    const { reportData = {} } = report;
    let { headers = [] } = reportData;
    let header = headers[index];
    if (header) {
      header = { ...header, [key]: value };
      headers[index] = header;
    }
    headers = [...headers];
    this.setReportState('headers')(headers);
  };

  addFilter = () => {
    const { report = {} } = this.props;
    const { reportData = {} } = report;
    let { filters = [] } = reportData;
    filters.push({ name: '', value: '', desc: '', type: '' });
    filters = [...filters];
    this.setReportState('filters')(filters);
  };

  removeFilter = index => () => {
    const { report = {} } = this.props;
    const { reportData = {} } = report;
    let { filters = [] } = reportData;
    if (filters && filters[index]) {
      filters.splice(index, 1);
    }
    filters = [...filters];
    this.setReportState('filters')(filters);
  };

  updateFilter = (index, key) => e => {
    const value = get(e, 'target') ? get(e, 'target.value') : e;
    const { report = {} } = this.props;
    const { reportData = {} } = report;
    let { filters = [] } = reportData;
    let filter = filters[index];
    if (filter) {
      filter = { ...filter, [key]: value };
      filters[index] = filter;
    }
    filters = [...filters];
    this.setReportState('filters')(filters);
  };

  headersFiltersDisabled = () => {
    const { report = {}, admin = {} } = this.props;
    const { reportData = {}, origQuery, origDatabase, origQueryLevel } = report;
    const adminId = admin.id;
    const {
      query = [],
      allHeaders,
      database,
      query_level,
      is_default,
    } = reportData;

    return (
      !allHeaders ||
      !allHeaders.length ||
      !query ||
      query !== origQuery ||
      database !== origDatabase ||
      query_level !== origQueryLevel
    );
  };

  testReportQuery = () => {
    const { testReportQuery } = this.props;
    testReportQuery();
  };

  renderHubListOptions = () => {
    const { allAvailableHubs = [] } = this.props;
    if (Array.isArray(allAvailableHubs)) {
      return allAvailableHubs.map(hub => (
        <Option key={hub.id} value={hub.id}>
          {hub.id} - {hub.name}
        </Option>
      ));
    }
    return [];
  };

  filterHubs = (input, option) => {
    const { allAvailableHubs = [] } = this.props;
    const opt = find(allAvailableHubs, { id: Number(option.key) });
    if (
      opt &&
      (opt.name.toLowerCase().indexOf(input.toLowerCase()) > -1 ||
        opt.id.toString().indexOf(input.toLowerCase()) > -1)
    ) {
      return true;
    }
    return false;
  };

  renderTimezoneSelect = (header, index) => {
    if (header.transform && header.transform === 'date') {
      return (
        <Col span={5}>
          <Select
            showSearch
            placeholder="Timezone"
            style={{ width: 150 }}
            dropdownMatchSelectWidth={false}
            onChange={this.updateHeader(index, 'timezone')}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            value={header.timezone || undefined}
          >
            {getTimezoneList().map(timezone => (
              <Option value={timezone}>{timezone}</Option>
            ))}
          </Select>
        </Col>
      );
    }
    return (
      <Col span={5}>
        <div />
      </Col>
    );
  };

  renderColumnFetcherQuery = (header, index) => {
    if (header.transform && header.transform === 'spread') {
      return (
        <Col span={5}>
          <Editor
            value={header.columnFetcherQuery || ''}
            onValueChange={this.updateHeader(index, 'columnFetcherQuery')}
            highlight={code => highlight(code, languages.sql)}
            placeholder="Optional: Add sql query to fetch the possible column names which this spread column can have."
            padding={20}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 16,
              border: '1px solid #ccc',
              minHeight: '150px',
              // margin: '20px 0',
            }}
            // disabled={is_default}
          />
        </Col>
      );
    }
    return null;
  };

  getDefaultInputsContent = () => (
    <Row className={`${s['ant-row']}`}>
      <Col span={7}>
        <Input
          placeholder="Hub Id"
          name="hubId"
          onChange={this.setReportDefaultValues('hubId')}
        />
        <Input
          placeholder="Cluster Id"
          name="clusterId"
          onChange={this.setReportDefaultValues('clusterId')}
        />
        <Button onClick={this.testReportQuery}>Confirm</Button>
      </Col>
    </Row>
  );

  setReportDefaultValues = key => e => {
    const value = get(e, 'target') ? get(e, 'target.value') : e;
    const {
      report: { reportData },
    } = this.props;
    const existingDefaultValues = reportData.default_values;
    const { setReportData } = this.props;
    setReportData({
      default_values: { ...existingDefaultValues, [key]: value },
    });
  };

  onQueryConfigEdit = ({updated_src}) => {
    this.setReportState('query_config')(updated_src);
  };

  render() {
    const {
      report = {},
      submitReportChanges,
      admin,
      allAvailableClusters,
      allAvailableHubs,
    } = this.props;
    const { reportData = {}, loading } = report;
    const adminId = admin.id;
    const {
      title,
      description,
      query,
      filters = [],
      headers = [],
      database,
      query_level,
      dataviewer_type,
      active,
      all_hubs,
      hubs,
      clusters,
      default_sort,
      related_chart_id,
      modules,
      editable_columns,
      allHeaders,
      allowed_actions,
      update_log,
      module_mode,
      query_config,
      enabled_for_organizations
    } = reportData;
    let { is_default } = reportData;
    is_default = false;
    if (loading) {
      return <FullPageLoading />;
    }
    return (
      <div className={`${s['anaytics-report-edit']}`}>
        {/* <h3>Edit</h3> */}
        <Row className={`${s['ant-row']}`}>
          <Col className={`${s['ant-col-title']}`} span={6}>
            Title
          </Col>
          <Col className={`${s['ant-col-inpt']}`} span={18}>
            <Input
              placeholder="Title"
              value={title}
              onChange={this.setReportState('title')}
              disabled={is_default}
            />
          </Col>
        </Row>
        <Row className={`${s['ant-row']}`}>
          <Col className={`${s['ant-col-title']}`} span={6}>
            Description
          </Col>
          <Col className={`${s['ant-col-inpt']}`} span={18}>
            <Input
              placeholder="Description"
              value={description}
              onChange={this.setReportState('description')}
              disabled={is_default}
            />
          </Col>
        </Row>
        <Row className={`${s['ant-row']}`}>
          <Col className={`${s['ant-col-title']}`} span={6}>
            Sql Query
          </Col>
          <Col className={`${s['ant-col-inpt']}`} span={18}>
            <Editor
              value={query || ''}
              onValueChange={this.setReportState('query')}
              highlight={code => highlight(code, languages.sql)}
              placeholder={placeholder}
              padding={20}
              style={{
                fontFamily: '"Fira code", "Fira Mono", monospace',
                fontSize: 16,
                border: '1px solid #ccc',
                minHeight: '180px',
                margin: '20px 0',
              }}
              disabled={is_default}
            />
          </Col>
        </Row>
        <Row className={`${s['ant-row']}`}>
          <Col className={`${s['ant-col-title']}`} span={6}>
            Sql Query Config
          </Col>
          <Col className={`${s['ant-col-inpt']}`} span={18}>
             <ReactJson
              style={{
                overflow: 'auto',
                fontSize: '12px',
                fontFamily: '"Fira code", "Fira Mono", monospace',
                fontSize: 16,
                border: '1px solid #ccc',
                minHeight: '180px',
                margin: '20px 0',
              }}
              name="QueryConfig"
              collapsed
              src={query_config ?? {}} 
              onEdit={this.onQueryConfigEdit}
              onAdd={this.onQueryConfigEdit}
              onDelete={this.onQueryConfigEdit}
            />
          </Col>
        </Row>
        <Row className={`${s['ant-row']}`}>
          <Col className={`${s['ant-col-title']}`} span={6}>
            Database
          </Col>
          <Col className={`${s['ant-col-inpt']}`} span={18}>
            <Radio.Group
              options={DATABASE_OPTIONS}
              onChange={this.setReportState('database')}
              value={database}
              disabled={is_default}
            />
          </Col>
        </Row>
        {reportData.database === 'analytics' ? (
          <Row className={`${s['ant-row']}`}>
            <Col className={`${s['ant-col-title']}`} span={6}>
              Related Chart ID
            </Col>
            <Col className={`${s['ant-col-inpt']}`} span={18}>
              <Input
                type="number"
                placeholder="Chart Id"
                value={related_chart_id}
                onChange={this.setReportState('related_chart_id')}
                disabled={is_default}
                required
              />
            </Col>
          </Row>
        ) : null}
        <Row className={`${s['ant-row']}`}>
          <Col className={`${s['ant-col-title']}`} span={6}>
            Dataviewer Type
          </Col>
          <Col className={`${s['ant-col-inpt']}`} span={18}>
            <Radio.Group
              options={QUERY_LEVEL_OPTIONS}
              onChange={this.setReportState('dataviewer_type')}
              value={dataviewer_type}
              disabled={is_default}
            />
          </Col>
        </Row>
        <Row className={`${s['ant-row']}`}>
          <Col className={`${s['ant-col-title']}`} span={6}>
            Query Level
          </Col>
          <Col className={`${s['ant-col-inpt']}`} span={18}>
            <Radio.Group
              options={QUERY_LEVEL_OPTIONS}
              onChange={this.setReportState('query_level')}
              value={query_level}
              disabled={is_default}
            />
          </Col>
        </Row>
        <Row className={`${s['ant-row']}`} justify="end">
          <Popover
            title="Add any default values if required"
            content={this.getDefaultInputsContent()}
            trigger="click"
            placement="topRight"
          >
            <Button>Test Query</Button>
          </Popover>
        </Row>
        <Divider />
        <Row className={`${s['ant-row']}`}>
          <h3>Headers</h3>
          {headers.map((header, i) => (
            <div style={{ marginLeft: '20px' }}>
              <Row className={`${s['ant-row']}`}>
                <Col span={5}>
                  <Select
                    value={header.column || undefined}
                    style={{ width: 120 }}
                    onChange={this.updateHeader(i, 'column')}
                    disabled={this.headersFiltersDisabled()}
                    dropdownMatchSelectWidth={false}
                  >
                    {this.getAllHeaderOptions()}
                  </Select>
                </Col>
                <Col span={5}>
                  <Input
                    placeholder="Title"
                    value={header.title}
                    style={{ width: 200 }}
                    onChange={this.updateHeader(i, 'title')}
                    disabled={this.headersFiltersDisabled()}
                  />
                </Col>
                <Col span={5}>
                  <Select
                    placeholder="Transform"
                    onChange={this.updateHeader(i, 'transform')}
                    value={header.transform || undefined}
                    style={{ width: 120 }}
                    disabled={this.headersFiltersDisabled()}
                    dropdownMatchSelectWidth={false}
                  >
                    <Option key="null" value={null}>
                      No Transofm
                    </Option>
                    <Option key="usertype" value="userType">
                      User Type
                    </Option>
                    <Option key="usertype" value="date">
                      Date
                    </Option>
                    <Option
                      key="jobflexcustomfields"
                      value="jobFlexCustomFields"
                    >
                      Job Flex Custom Fields
                    </Option>
                    <Option key="spread" value="spread">
                      Spread
                    </Option>
                    <Option key="s3doc" value="s3doc">
                      S3 Document
                    </Option>
                    <Option key="list" value="list">
                      List
                    </Option>
                  </Select>
                </Col>
                {this.renderTimezoneSelect(header, i)}
                {this.renderColumnFetcherQuery(header, i)}
                <Col
                  onClick={
                    !this.headersFiltersDisabled() && this.removeHeader(i)
                  }
                  style={{ textAlign: 'right' }}
                  span={2}
                >
                  Remove
                </Col>
              </Row>
              <Divider />
            </div>
          ))}
        </Row>
        <Row className={`${s['ant-row']}`} justify="end">
          <Button
            disabled={this.headersFiltersDisabled()}
            onClick={this.addHeader}
          >
            Add Column
          </Button>
        </Row>
        <Divider />
        <AddActions
          onUpdate={this.setReportState('allowed_actions')}
          actions={allowed_actions}
        />
        <Divider />
        <AddUpdateLog onChange={this.setReportState('update_log')} />
        <EditableColumns
          editableColumns={editable_columns}
          onUpdate={this.setReportState('editable_columns')}
          allHeaders={allHeaders}
          database={database}
        />
        <Divider />
        <Row className={`${s['ant-row']}`}>
          <Row className={`${s['ant-row']}`}>
            <h3>Filters</h3>
          </Row>
          {filters.map((filter, i) => (
            <div style={{ marginLeft: '20px' }}>
              <Row className={`${s['ant-row']}`}>
                <Col span={7}>
                  <Select
                    placeholder="Filter on Column"
                    onChange={this.updateFilter(i, 'value')}
                    value={filter.value || undefined}
                    style={{ width: 120 }}
                    disabled={this.headersFiltersDisabled()}
                    dropdownMatchSelectWidth={false}
                  >
                    {this.getAllFilterOptions()}
                  </Select>
                </Col>
                <Col span={7}>
                  <Input
                    value={filter.name}
                    style={{ width: 200 }}
                    placeholder="Name"
                    onChange={this.updateFilter(i, 'name')}
                    disabled={this.headersFiltersDisabled()}
                  />
                </Col>
                <Col span={7}>
                  <Input
                    value={filter.desc}
                    style={{ width: 200 }}
                    placeholder="Description"
                    onChange={this.updateFilter(i, 'desc')}
                    disabled={this.headersFiltersDisabled()}
                  />
                </Col>
                <Col
                  onClick={
                    !this.headersFiltersDisabled() && this.removeFilter(i)
                  }
                  style={{ textAlign: 'right' }}
                  span={3}
                >
                  Remove
                </Col>
              </Row>
              <Row className={`${s['ant-row']}`}>
                <Col span={7}>
                  <Select
                    value={filter.type || undefined}
                    placeholder="Filter Type"
                    style={{ width: 140 }}
                    onChange={this.updateFilter(i, 'type')}
                    disabled={this.headersFiltersDisabled()}
                  >
                    <Option value="text">Text</Option>
                    <Option value="number">Number</Option>
                    <Option value="date">Date</Option>
                    <Option value="relativeDate">Relative Date</Option>
                    <Option value="boolean">Boolean</Option>
                    <Option value="list">List</Option>
                    <Option value="textArray">Text Array</Option>
                    <Option value="object">Array of Objects</Option>
                  </Select>
                </Col>
                <Col span={7}>
                  {(filter.type === 'list' || filter.type === 'object') && (
                    <Select
                      placeholder="Options"
                      value={filter.options}
                      style={{ width: 120 }}
                      onChange={this.updateFilter(i, 'options')}
                      disabled={this.headersFiltersDisabled()}
                      dropdownMatchSelectWidth={false}
                    >
                      <Option value="usertype">User type</Option>
                      <Option value="distinct">Distinct Column Values</Option>
                      <Option value="mapValues">
                        Map Values Based On Column
                      </Option>
                      <Option value="demographicCustomFields">
                        Demographic Custom Fields
                      </Option>
                      <Option value="clusterHubs">Cluster Hubs</Option>
                    </Select>
                  )}
                </Col>
                <Col span={7}>
                  {filter.type === 'list' && (
                    <Checkbox
                      checked={filter.multiple}
                      onChange={this.setMultipleOption(i)}
                      disabled={this.headersFiltersDisabled()}
                    >
                      Can choose multiple options?
                    </Checkbox>
                  )}
                </Col>
              </Row>
              <Divider />
            </div>
          ))}
        </Row>
        <Row className={`${s['ant-row']}`} justify="end">
          <Button
            disabled={this.headersFiltersDisabled()}
            onClick={this.addFilter}
          >
            Add Filter
          </Button>
        </Row>
        <Divider />
        <Row className={`${s['ant-row']}`}>
          <Col className={`${s['ant-col-title']}`} span={6}>
            Default Sort
          </Col>
          <Col className={`${s['ant-col-inpt']}`} span={18}>
            <Select
              value={default_sort || undefined}
              style={{ width: 400 }}
              mode="multiple"
              onChange={this.setReportState('default_sort')}
              disabled={this.headersFiltersDisabled()}
            >
              {this.getAllSortColsOptions()}
            </Select>
          </Col>
        </Row>
        <Row className={`${s['ant-row']}`} style={{ display: 'flex' }}>
          <Col className={`${s['ant-col-title']}`} span={6}>
            Module Name
          </Col>
          <Col
            className={`${s['ant-col-inpt']}`}
            span={18}
            style={{ display: 'flex', flexWrap: 'wrap' }}
          >
            <Col
              span={10}
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Select
                value={modules}
                style={{ width: 400 }}
                mode="tags"
                onChange={this.setReportState('modules')}
                disabled={this.headersFiltersDisabled()}
              />
            </Col>
            <Col
              span={8}
              style={{ display: 'flex', justifyContent: 'space-around' }}
            >
              <Col span={2}>AND</Col>
              <Col span={2}>
                <Switch
                  disabled={modules?.length < 2}
                  onChange={this.setModuleMode}
                  checked={module_mode === 'OR'}
                />
              </Col>
              <Col span={2}>OR</Col>
            </Col>
          </Col>
        </Row>
        <Divider />
        <Row className={`${s['ant-row']}`}>
          <Col className={`${s['ant-col-title']}`} span={6}>
            Use for
          </Col>
          <Col span={4}>
            <Checkbox
              disabled={is_default}
              onChange={this.setAllHubs}
              checked={all_hubs}
            >
              {dataviewer_type === 'cluster' ? 'All Clusters' : 'All hubs'}
            </Checkbox>
          </Col>
          <Col span={2} style={{ textAlign: 'center' }}>
            <span> or </span>
          </Col>
          <Col span={6}>
            <ListSelector
              listOptions={
                dataviewer_type === 'cluster'
                  ? allAvailableClusters
                  : allAvailableHubs
              }
              onUpdate={
                dataviewer_type === 'cluster'
                  ? this.setReportState('clusters')
                  : this.setReportState('hubs')
              }
              type={dataviewer_type}
              value={dataviewer_type === 'cluster' ? clusters : hubs}
              disabled={all_hubs || is_default}
            />
          </Col>
        </Row>
        <Row className={`${s['ant-row']}`}>
          <Col className={`${s['ant-col-title']}`} span={6}>
            Enable for Organizations 
          </Col>
          <Col className={`${s['ant-col-inpt']}`} span={18}>
            <Switch
              disabled={is_default}
              checked={enabled_for_organizations}
              onChange={this.setReportState('enabled_for_organizations')}
            />
          </Col>
        </Row>
        <Row className={`${s['ant-row']}`}>
          <Col className={`${s['ant-col-title']}`} span={6}>
            Activate
          </Col>
          <Col className={`${s['ant-col-inpt']}`} span={18}>
            <Switch
              disabled={is_default}
              checked={active}
              onChange={this.setReportState('active')}
            />
          </Col>
        </Row>
        <Divider />
        <Button
          onClick={!this.headersFiltersDisabled() && submitReportChanges}
          disabled={this.headersFiltersDisabled() || !update_log}
        >
          Submit
        </Button>
      </div>
    );
  }
}

const mapStateToProps = ({ analyticsReport, admin }) => {
  const { report, allAvailableHubs, allAvailableClusters } = analyticsReport;
  return {
    admin,
    report,
    allAvailableHubs,
    allAvailableClusters,
  };
};

const mapDispatchToProps = {
  fetchAnalyticsReport,
  setReportData,
  testReportQuery,
  submitReportChanges,
  createNewAnalyticReport,
  fetchAllHubList,
  fetchAllClusterList,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AnalyticsReportList)
);
